<template>
    <div v-if="device">

        <!-- Content -->
        <standard-job-start :signatureImageUrl="signatureImageUrl"></standard-job-start>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': footerActive}"
                @nextButtonClicked="setWaiverOptionChecked()"
                :nextLabel="this.nextRepairStep($route.name).title"
                :nextRoute="this.nextRepairStep($route.name).routeName">
        </fixed-footer>


        <!-- Change Device Modal -->
        <change-device-modal></change-device-modal>

        <!-- Waiver signature pad -->
        <b-modal
                body-class="px-0 pb-0"
                hide-footer
                id="waiver-signature-modal"
                size="fs"
                title="Sign Waiver">
            <signature-pad @signaturePadSigned="waiverHasBeenSigned($event)" model-id="waiver-signature-modal"></signature-pad>
        </b-modal>

    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";

    export default {
        props:['deviceId', 'jobId'],

        data() {
            return {
                footerActive: true
            }
        },

        computed: {
            ...mapGetters([
                "device",
                "job",
                "nextRepairStep",
                "prevRepairStep",
                "signatureImage",
                "waiverOptions",
                "waiverSigned"
            ]),

            ...mapState([
                "waiverName"
            ]),

            signatureImageUrl() {
                if (this.signatureImage) {
                    return `https://firebasestorage.googleapis.com/v0/b/sinuous-vortex-201512.appspot.com/o/signatures%2F${this.jobId}%2F${this.signatureImage.filename}?alt=media`;
                }

                return false;
            },

            isAsset() {
                return (
                    this.job.job_type === 'refurbishment' ||
                    this.job.job_type === 'mail-in' ||
                    this.job.job_type === 'customer-mail-in' ||
                    this.job.job_type === 're-repair'
                );
            }
        },

        mounted() {
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            }).then(() =>{
                if (this.isAsset) {
                    this.$router.push(`/jobs/${this.jobId}/repair/${this.deviceId}/scan-asset`);
                    return;
                }

                this.loadSignatureImage({
                    jobId: this.jobId,
                    imageableId: this.deviceId,
                });
                this.setCurrentStep({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    currentStep: this.$route.name,
                });
            });

            this.loadWaiverOptions({
                jobId: this.jobId,
                deviceId: this.deviceId,
            });
        },

        methods: {
            ...mapActions([
                "addSignature",
                "loadDeviceForJob",
                "loadSignatureImage",
                "loadWaiverOptions",
                "setCurrentStep",
                "setWaiverName",
                "setWaiverOptionChecked",
                "setWaiverSigned"
            ]),

            /** The waiver has been signed */
            waiverHasBeenSigned(signaturePadData) {

                this.$bvModal.hide('waiver-signature-modal');

                let promise1 = this.setWaiverName({
                    jobId: this.jobId,
                    deviceId: this.deviceId
                });

                let promise2 = this.setWaiverOptionChecked({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                });

                Promise.all([
                    promise1,
                    promise2
                ]).then(() => {
                    this.setWaiverSigned({
                        jobId: this.jobId,
                        deviceId: this.deviceId,
                    })
                });

                this.addSignature({
                    jobId: this.jobId,
                    imageableId: this.deviceId,
                    imageableType: "App\\Models\\Waiver",
                    imageData: signaturePadData,
                    signatureType: "device-waiver"
                }).then(() => {
                    this.loadSignatureImage({
                        jobId: this.jobId,
                        imageableId: this.deviceId,
                    })
                });
            }
        }
    }
</script>

<style scoped>

</style>